import { render, staticRenderFns } from "./InlineImageEditor.vue?vue&type=template&id=163dfaaa&scoped=true&"
import script from "./InlineImageEditor.vue?vue&type=script&lang=js&"
export * from "./InlineImageEditor.vue?vue&type=script&lang=js&"
import style0 from "./InlineImageEditor.vue?vue&type=style&index=0&id=163dfaaa&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "163dfaaa",
  null
  
)

export default component.exports